import { Chrome } from 'vue-color';
import { ImageUtilitiesMixin } from '@/mixins/image-utilities/image-utilities';
import { storeActions } from '@/store/store-types';

export default {
  mixins: [ImageUtilitiesMixin],
  components: {
    'chrome-picker': Chrome
  },
  props: {
    isMobile: {
      type: Boolean,
      require: true
    },
    color: {
      type: String
    },
    formData: {
      type: Object,
      required: true
    },
    formErrors: {
      type: Object,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateBGColor(color) {
      this.formData.brandingColor = color.hex8.toString();
    },
    async onChangeBrandingImageFile(file) {
      if (file) {
        var fileString;
        try {
          fileString = await this.getBase64Async(file);
        } catch (err) {
          this.$log.error('Error uploading image: ', err.message || err);
          this.$store.dispatch(storeActions.notifications.error, err.message || err);
        }
        this.formData.brandingImageFile = fileString;
        this.formData.brandingImageSourceForDisplay = this.formData.brandingImageFile;
      }
    },
    onChangeBrandingImageSource() {
      this.formData.brandingImageSourceForDisplay = this.formData.brandingImageSource;
    },
    onChangeBrandingImageType() {
      this.formData.brandingImageSourceForDisplay = null;
      this.formData.brandingImageSource = null;
      this.v.brandingImageType.$touch();
    }
  }
};
