<template>
  <v-layout wrap>
      <v-flex xs12>
        <v-text-field
          id="tfInternalUrl"
          v-model="formData.liveInternalSource"
          label="Multicast URL"
          :error-messages="formErrors.liveInternalSource"
          @input="v.liveInternalSource.$touch()"
          @blur="v.liveInternalSource.$touch()"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          id="tfInternalUrlCache"
          v-model="formData.liveInternalCacheSource"
          label="Omnicache URL"
          :error-messages="formErrors.liveInternalCacheSource"
          @input="v.liveInternalCacheSource.$touch()"
          @blur="v.liveInternalCacheSource.$touch()"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          id="tfNginxUrlCache"
          v-model="formData.liveNginxCacheSource"
          label="NGINX URL"
          :error-messages="formErrors.liveNginxCacheSource"
          @input="v.liveNginxCacheSource.$touch()"
          @blur="v.liveNginxCacheSource.$touch()"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          id="tfExternalUrl"
          v-model="formData.liveExternalSource"
          label="External URL"
          :error-messages="formErrors.liveExternalSource"
          @input="v.liveExternalSource.$touch()"
          @blur="v.liveExternalSource.$touch()"
        ></v-text-field>
      </v-flex>
    </v-layout>
</template>
<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    formErrors: {
      type: Object,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  }
};
</script>
