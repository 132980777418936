export const ImageUtilitiesMixin = {
  methods: {
    async getBase64Async(file) {
      return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
          return resolve(reader.result);
        };
        reader.onerror = function(error) {
          return reject(error);
        };
      });
    }
  }
};
