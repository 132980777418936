<template>
  <v-layout wrap>
    <v-flex xs12>
      <v-text-field
        id="tfVodUrl"
        v-model="formData.vodSource"
        label="VOD URL"
        :error-messages="formErrors.vodSource"
        @input="v.vodSource.$touch()"
        @blur="v.vodSource.$touch()"
      ></v-text-field>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    formErrors: {
      type: Object,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  }
};
</script>
