import StreamSourcesLiveInputs from '@/components/stream-sources-live-inputs/stream-sources-live-inputs.vue';
import StreamSourcesOnHoldInputs from '@/components/stream-sources-on-hold-inputs/stream-sources-on-hold-inputs.vue';
import StreamSourcesVodInputs from '@/components/stream-sources-vod-inputs/stream-sources-vod-inputs.vue';
import { ViewUtilitiesMixin } from '@/mixins/view-utilities/view-utilities';
import { storeActions } from '@/store/store-types';
import _ from 'lodash';
import shortid from 'shortid';
import sjv from 'simple-js-validator';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, url } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin, ViewUtilitiesMixin],
  components: {
    StreamSourcesLiveInputs,
    StreamSourcesVodInputs,
    StreamSourcesOnHoldInputs
  },
  data: () => ({
    dialog: false,
    search: '',
    sourcesLoading: false,
    isMobile: false,
    mobileModalHeight: null,
    expanded: [],
    color: {},
    tab: null,
    headers: [
      { text: 'Status', value: 'streamStatus', align: 'left', width: '90px' },
      { text: 'ID', value: 'streamId', align: 'left' },
      { text: 'Title', value: 'title', align: 'left' },
      { text: 'Sources', value: 'stream.streams', sortable: false, align: 'left', filterable: false },
      { text: 'Actions', value: 'action', sortable: false, align: 'left' },
      { text: 'Multicast URL', value: 'stream.live.internal', align: ' d-none' },
      { text: 'Omnicache URL', value: 'stream.live.internalCache', align: ' d-none' },
      { text: 'NGINX URL', value: 'stream.live.nginxCache', align: ' d-none' },
      { text: 'External URL', value: 'stream.live.external', align: ' d-none' },
      { text: 'VOD URL', value: 'stream.vod.source', align: ' d-none' }
    ],
    actionIndex: -1,
    // form fields
    formGeneral: {
      streamStatus: 'ONHOLD',
      chatEnabled: false,
      chatChannelArn: '',
      streamId: null,
      title: null,
      createdDateTime: null,
      createdBy: null,
      updatedDateTime: null,
      updatedBy: null
    },
    formLive: {
      liveInternalSource: null,
      liveInternalCacheSource: null,
      liveNginxCacheSource: null,
      liveExternalSource: null
    },
    formVod: {
      vodSource: null
    },
    formOnHold: {
      brandingImageType: 'N/A',
      brandingImageSource: null,
      brandingImageFile: null,
      brandingImageSourceForDisplay: null,
      brandingColor: '#FFFFFF'
    }
  }),

  validations: {
    formGeneral: {
      streamId: {
        required,
        isUnique(value) {
          if (!value || value === '') return true; // required validation ensures it is populated; no need to double check here
          if (this.actionIndex > -1) return true;
          const found = this.$store.state.streamSources.items.find(s => s.streamId.toLowerCase() === value.toLowerCase());
          return !found;
        },
        hasNoWhiteSpace(value) {
          if (!value || value === '') return true;
          var inValid = new RegExp('[\\s]');
          if (inValid.test(value)) return false;
          else return true;
        }
      },
      streamStatus: {
        required
      },
      chatEnabled: {
        required
      },
      title: {
        required
      }
    },
    formLive: {
      liveInternalSource: {
        requiredIfLive: requiredIf(function() {
          // true = invalid; false/no return = valid
          if (this.formGeneral.streamStatus === 'LIVE') {
            return sjv.isEmpty(this.formLive.liveInternalSource) && sjv.isEmpty(this.formLive.liveExternalSource); // if live, then required
          }
        })
      },
      liveInternalCacheSource: {
        url,
        requiredIfLive: requiredIf(function() {
          // true = invalid; false/no return = valid
          if (this.formGeneral.streamStatus === 'LIVE') {
            return sjv.isEmpty(this.formLive.liveInternalCacheSource) && sjv.isEmpty(this.formLive.liveNginxCacheSource); // if live, then required
          }
        })
      },
      liveNginxCacheSource: {
        url,
        requiredIfLive: requiredIf(function() {
          // true = invalid; false/no return = valid
          if (this.formGeneral.streamStatus === 'LIVE') {
            return sjv.isEmpty(this.formLive.liveInternalCacheSource) && sjv.isEmpty(this.formLive.liveNginxCacheSource); // if live, then required
          }
        })
      },
      liveExternalSource: {
        url,
        requiredIfLive: requiredIf(function() {
          // true = invalid; false/no return = valid
          if (this.formGeneral.streamStatus === 'LIVE') {
            return sjv.isEmpty(this.formLive.liveExternalSource) && sjv.isEmpty(this.formLive.liveInternalSource); // if live, then required
          }
        })
      }
    },
    formVod: {
      vodSource: {
        url,
        requiredIfVod: requiredIf(function() {
          // true = invalid; false/no return = valid
          if (this.formGeneral.streamStatus === 'VOD') {
            return sjv.isEmpty(this.formVod.vodSource); // if vod, then required
          }
        })
      }
    },
    formOnHold: {
      brandingImageType: {
        imageSourceRequiredIfUrl: function() {
          // true = valid; false = invalid
          if (this.formOnHold.brandingImageType === 'URL') {
            return sjv.isNotEmpty(this.formOnHold.brandingImageSource); // if image type url, then image source required
          } else {
            return true;
          }
        },
        imageSourceMustBeUrlIfUrl: function() {
          // true = valid; false = invalid
          if (this.formOnHold.brandingImageType === 'URL') {
            return url(this.formOnHold.brandingImageSource); // if image type url, then image source required
          } else {
            return true;
          }
        },
        imageFileRequiredIfUpload: function(val) {
          // true = valid; false = invalid
          if (this.formOnHold.brandingImageType === 'UPLOAD') {
            // if the file is already been uploaded (this is an edit) then the file is null'd out but there should be a image source for display (presigned url)
            return sjv.isNotEmpty(this.formOnHold.brandingImageFile) || sjv.isNotEmpty(this.formOnHold.brandingImageSourceForDisplay); // if image type url, then image file or display source required
          } else {
            return true;
          }
        },
        mustBeUploadOrUrlIfOnHold: function(val) {
          // true = valid; false = invalid
          if (this.formGeneral.streamStatus === 'ONHOLD') {
            return (val === 'UPLOAD' || val === 'URL'); // if on hold, then must be upload or url
          } else {
            return true;
          }
        }
      },
      brandingColor: {
        requiredIfOnHold: requiredIf(function() {
          // true = invalid; false/no return = valid
          if (this.formGeneral.streamStatus === 'ONHOLD') {
            return sjv.isEmpty(this.formOnHold.brandingColor); // if on hold, then required
          }
        }),
        mustStartWithHashtag: function(val) {
          // true = valid; false = invalid
          return (val && val.indexOf('#') === 0) || false;
        }
      }
    }
  },

  computed: {
    streamIdErrors() {
      const errors = [];
      // if (!this.$v.formGeneral.streamId.$dirty) return errors;
      !this.$v.formGeneral.streamId.required && errors.push('Stream Id is required');
      !this.$v.formGeneral.streamId.isUnique && errors.push('Stream Id must be unique');
      !this.$v.formGeneral.streamId.hasNoWhiteSpace && errors.push('Stream Id cannot have whitespace');
      return errors;
    },
    streamStatusErrors() {
      const errors = [];
      // if (!this.$v.formGeneral.streamStatus.$dirty) return errors;
      !this.$v.formGeneral.streamStatus.required && errors.push('Stream Status is required');
      return errors;
    },
    chatEnabledErrors() {
      const errors = [];
      !this.$v.formGeneral.chatEnabled.required && errors.push('Stream chat selection is required');
      return errors;
    },
    titleErrors() {
      const errors = [];
      // if (!this.$v.formGeneral.title.$dirty) return errors;
      !this.$v.formGeneral.title.required && errors.push('Title is required');
      return errors;
    },
    liveInternalSourceErrors() {
      const errors = [];
      // if (!this.$v.formLive.liveInternalSource.$dirty) return errors;
      !this.$v.formLive.liveInternalSource.requiredIfLive && errors.push('Either a Multicast or External source is required while live');
      return errors;
    },
    liveInternalCacheSourceErrors() {
      const errors = [];
      // if (!this.$v.formLive.liveInternalCacheSource.$dirty) return errors;
      !this.$v.formLive.liveInternalCacheSource.requiredIfLive && errors.push('At least one cache, Omnicache or Nginx, is required while live');
      !this.$v.formLive.liveInternalCacheSource.url && errors.push('Omnicache must be a valid url');
      return errors;
    },
    liveNginxCacheSourceErrors() {
      const errors = [];
      // if (!this.$v.formLive.liveInternalCacheSource.$dirty) return errors;
      !this.$v.formLive.liveNginxCacheSource.requiredIfLive && errors.push('At least one cache, Omnicache or Nginx, is required while live');
      !this.$v.formLive.liveNginxCacheSource.url && errors.push('NGINX must be a valid url');
      return errors;
    },
    liveExternalSourceErrors() {
      const errors = [];
      // if (!this.$v.formLive.liveExternalSource.$dirty) return errors;
      !this.$v.formLive.liveExternalSource.requiredIfLive && errors.push('Either a Multicast or External source is required while live');
      !this.$v.formLive.liveExternalSource.url && errors.push('External must be a valid url');
      return errors;
    },
    vodSourceErrors() {
      const errors = [];
      // if (!this.$v.formVod.vodSource.$dirty) return errors;
      !this.$v.formVod.vodSource.requiredIfVod && errors.push('VOD is required when on demand');
      !this.$v.formVod.vodSource.url && errors.push('VOD must be a valid url');
      return errors;
    },
    brandingImageTypeErrors() {
      const errors = [];
      // if (!this.$v.formOnHold.brandingImageType.$dirty) return errors;
      !this.$v.formOnHold.brandingImageType.mustBeUploadOrUrlIfOnHold && errors.push('Image type cannot be N/A when on hold');
      return errors;
    },
    brandingImageTypeErrorsForImageFile() {
      const errors = [];
      // if (!this.$v.formOnHold.brandingImageType.$dirty) return errors;
      !this.$v.formOnHold.brandingImageType.imageFileRequiredIfUpload && errors.push('Image file required when image type upload');
      return errors;
    },
    brandingImageTypeErrorsForImageSource() {
      const errors = [];
      // if (!this.$v.formOnHold.brandingImageType.$dirty) return errors;
      !this.$v.formOnHold.brandingImageType.imageSourceRequiredIfUrl && errors.push('Image source required when image type url');
      !this.$v.formOnHold.brandingImageType.imageSourceMustBeUrlIfUrl && errors.push('Image source must be url when image type url');
      return errors;
    },
    brandingColorErrors() {
      const errors = [];
      // if (!this.$v.formOnHold.brandingColor.$dirty) return errors;
      !this.$v.formOnHold.brandingColor.requiredIfOnHold && errors.push('Background color is required when on hold');
      !this.$v.formOnHold.brandingColor.mustStartWithHashtag && errors.push('Background color must start with "#"');
      return errors;
    },
    sources() {
      return this.$store.state.streamSources.items.map(item => {
        const sourceForDisplay = _.get(item, 'branding.image.sourceForDisplay', null);
        if (sourceForDisplay === 'not-found') {
          this.$log.warn('branding.image.sourceForDisplay is "not-found"; thus, nulling this value for streamId: ' + item.streamId);
          _.set(item, 'branding.image.sourceForDisplay', null);
        }
        return item;
      });
    },
    formTitle() {
      return (this.actionIndex === -1 ? 'New Stream' : 'Edit Stream') + ' (' + this.formGeneral.streamId + ')';
    },
    sourcesForDisplay() {
      const newSources = this.sources.slice();
      newSources.map((item, index) => {
        item.index = index;
        const isLive = _.get(item, 'stream.live.isLive', false);
        const isVod = _.get(item, 'stream.vod.isVod', false);
        item.streamStatus = this.determineStreamStatus(isLive, isVod);
        item.chatEnabled = _.get(item, 'chat.isEnabled', false);
        item.chatChannelArn = _.get(item, 'chat.channelArn', '');

        const internal = _.get(item, 'stream.live.internal', '');
        const internalCache = _.get(item, 'stream.live.internalCache', '');
        const nginxCache = _.get(item, 'stream.live.nginxCache', '');
        const external = _.get(item, 'stream.live.external', '');
        const vod = _.get(item, 'stream.vod.source', '');

        const internalDisplay = '<b>multicast:</b>&nbsp&nbsp' + (internal ? internal + '<br/>' : 'n/a <br/>');
        const internalCacheDisplay = '<b>omnicache:</b>&nbsp&nbsp&nbsp&nbsp&nbsp' + (internalCache ? internalCache + '<br/>' : 'n/a <br/>');
        const nginxCacheDisplay = '<b>nginx:</b>&nbsp&nbsp&nbsp&nbsp&nbsp' + (nginxCache ? nginxCache + '<br/>' : 'n/a <br/>');
        const externalDisplay = '<b>external:</b>&nbsp&nbsp' + (external ? external + '<br/>' : 'n/a <br/>');
        const vodDisplay = '<b>vod:</b>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp' + (vod ? vod + '<br/>' : 'n/a <br/>');
        item.stream.streams = internalDisplay + internalCacheDisplay + nginxCacheDisplay + externalDisplay + vodDisplay;
      });
      return newSources;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },

  async mounted() {
    this.sourcesLoading = true;
    await this.storeDispatchWithNotification({
      dispatchAction: storeActions.streamSources.list,
      data: null,
      infoMessage: null,
      successMessage: null,
      errorMessage: 'Error occurred trying to get the stream sources'
    });
    this.sourcesLoading = false;
  },

  methods: {
    onChangeStreamStatus(status) {
      this.tab = 'tab' + status;
      this.$v.formGeneral.streamStatus.$touch();
    },
    onChangeChatEnabled(status) {
      this.$v.formGeneral.chatEnabled.$touch();
    },

    determineFlexItemClass(item, key, hypenHandling) {
      let expectedLineCount = 0;
      const got = _.get(item, key, '') || '';

      if (got.length <= 40) {
        expectedLineCount = 1;
      } else if (got.length <= 80) {
        expectedLineCount = 2;
      } else if (got.length <= 120) {
        expectedLineCount = 3;
      } else {
        expectedLineCount = 4;
      }

      if (hypenHandling && got.indexOf('-') > -1) {
        // hyphens cause weird line breaks, so just add extra line for it
        expectedLineCount++;
      }

      return expectedLineCount <= 3 ? 'flex-item-' + expectedLineCount + '-line' : 'flex-item-ellipsis-rtl';
    },

    determineImageIsUrl(brandingImageType) {
      let imageIsUrl = null;
      if (brandingImageType === 'URL') {
        imageIsUrl = true;
      } else if (brandingImageType === 'UPLOAD') {
        imageIsUrl = false;
      }
      // else
      // brandingImageType === "N/A"
      // imageIsUrl = null

      return imageIsUrl;
    },

    mapToDatabaseModel() {
      return {
        streamId: this.formGeneral.streamId,
        title: this.formGeneral.title,
        stream: {
          live: {
            isLive: this.formGeneral.streamStatus === 'LIVE',
            internal: this.formLive.liveInternalSource,
            internalCache: this.formLive.liveInternalCacheSource,
            nginxCache: this.formLive.liveNginxCacheSource,
            external: this.formLive.liveExternalSource
          },
          vod: {
            isVod: this.formGeneral.streamStatus === 'VOD',
            source: this.formVod.vodSource
          }
        },
        chat: {
          isEnabled: this.formGeneral.chatEnabled,
          channelArn: this.formGeneral.chatChannelArn
        },
        branding: {
          image: {
            isUrl: this.determineImageIsUrl(this.formOnHold.brandingImageType),
            source: this.formOnHold.brandingImageSource,
            file: this.formOnHold.brandingImageFile
          },
          color: this.formOnHold.brandingColor || '#FFFFFF'
        },
        created: {
          dateTime: this.formGeneral.createdDateTime,
          by: this.formGeneral.createdBy
        },
        updated: {
          dateTime: this.formGeneral.updatedDateTime,
          by: this.formGeneral.updatedBy
        }
      };
    },

    determineStreamStatus(isLive, isVod) {
      let streamStatus;
      if (isLive) {
        streamStatus = 'LIVE';
      } else if (isVod) {
        streamStatus = 'VOD';
      } else {
        streamStatus = 'ONHOLD';
      }

      return streamStatus;
    },

    determineBrandingImageType(imageIsUrl) {
      let brandingImageType;
      if (imageIsUrl === true) {
        brandingImageType = 'URL';
      } else if (imageIsUrl === false) {
        brandingImageType = 'UPLOAD';
      } else {
        // imageIsUrl === null
        brandingImageType = 'N/A';
      }

      return brandingImageType;
    },

    mapToFormModel(item) {
      this.formGeneral.streamId = _.get(item, 'streamId', null);
      this.formGeneral.title = _.get(item, 'title', null);
      this.formGeneral.createdDateTime = _.get(item, 'created.dateTime', null);
      this.formGeneral.createdBy = _.get(item, 'created.by', null);
      this.formGeneral.updatedDateTime = _.get(item, 'updated.dateTime', null);
      this.formGeneral.updatedBy = _.get(item, 'updated.by', null);
      this.formLive.liveInternalSource = _.get(item, 'stream.live.internal', null);
      this.formLive.liveInternalCacheSource = _.get(item, 'stream.live.internalCache', null);
      this.formLive.liveNginxCacheSource = _.get(item, 'stream.live.nginxCache', null);
      this.formLive.liveExternalSource = _.get(item, 'stream.live.external', null);
      this.formVod.vodSource = _.get(item, 'stream.vod.source', null);
      this.formOnHold.brandingImageFile = _.get(item, 'branding.image.file', null);
      this.formOnHold.brandingImageSource = _.get(item, 'branding.image.source', null);
      this.formOnHold.brandingImageSourceForDisplay = _.get(item, 'branding.image.sourceForDisplay', null);
      this.formOnHold.brandingColor = _.get(item, 'branding.color', '#FFFFFF');

      const isLive = _.get(item, 'stream.live.isLive', false);
      const isVod = _.get(item, 'stream.vod.isVod', false);
      this.formGeneral.streamStatus = this.determineStreamStatus(isLive, isVod);
      this.formGeneral.chatEnabled = _.get(item, 'chat.isEnabled', false);
      this.formGeneral.chatChannelArn = _.get(item, 'chat.channelArn', '');
      const imageIsUrl = _.get(item, 'branding.image.isUrl', null);
      this.formOnHold.brandingImageType = this.determineBrandingImageType(imageIsUrl);
    },

    editItem(item) {
      this.actionIndex = this.sources.indexOf(item);
      this.mapToFormModel(item);
      this.color = this.formOnHold.brandingColor; // update color picker with current value
      this.tab = 'tab' + this.formGeneral.streamStatus; // update tab with current value
      this.dialog = true;
    },

    openModal() {
      this.color = this.formOnHold.brandingColor; // update color picker with current value
      this.tab = 'tab' + this.formGeneral.streamStatus; // update tab with current value
      this.formGeneral.streamId = shortid.generate();
      this.dialog = true;
    },

    deleteItemYesAction() {
      const data = this.sources[this.actionIndex];
      this.deleteStream(data);
      this.$snotify.remove();
      this.actionIndex = -1;
    },

    deleteItemNoAction() {
      this.$snotify.remove();
    },

    deleteItem(item) {
      this.actionIndex = this.sources.indexOf(item);
      this.$snotify.confirm('Are you sure you want to delete this item?', {
        buttons: [
          { text: 'Yes', action: this.deleteItemYesAction, bold: false },
          { text: 'No', action: this.deleteItemNoAction }
        ]
      });
    },

    toggleLive(item) {
      if (item.stream.live.isLive) {
        item.stream.live.isLive = false;
      } else {
        item.stream.live.isLive = true;
      }
      this.updateStream(item);
    },

    handleResize() {
      if (window.innerWidth < 736) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

      this.mobileModalHeight = window.innerHeight * 0.5;
    },

    close() {
      this.dialog = false;
      this.mapToFormModel({}); // reset to default values
      this.actionIndex = -1;
      this.tab = '';
      this.$v.$reset();
    },

    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const editedItem = this.mapToDatabaseModel();
        if (this.actionIndex > -1) {
          this.updateStream(editedItem);
        } else {
          this.createStream(editedItem);
        }
        this.close();
      }
    },

    async deleteStream(data) {
      this.storeDispatchWithNotification({
        dispatchAction: storeActions.streamSources.delete,
        data,
        infoMessage: 'Item deleted',
        successMessage: null,
        errorMessage: 'Error occurred trying to delete the data'
      });
    },

    async createStream(data) {
      this.storeDispatchWithNotification({
        dispatchAction: storeActions.streamSources.create,
        data,
        infoMessage: 'Item created',
        successMessage: null,
        errorMessage: 'Error occurred trying to create the data'
      });
    },

    async updateStream(data) {
      this.storeDispatchWithNotification({
        dispatchAction: storeActions.streamSources.update,
        data,
        infoMessage: 'Item updated',
        successMessage: null,
        errorMessage: 'Error occurred trying to update the data'
      });
    }
  }
};
